import { ChildArray, FromAny, Validate, isBoolean, isNumber, isString, notEmpty } from "@utils/class-from-any";
import { DictionaryQueryParams } from "../search.interface";

export { YoutubeSearchTagCloudFromJSON, YoutubeSearchTagCloud, YoutubeSearchTag };

interface YoutubeSearchTag extends DictionaryQueryParams {
    pageViews: number;
}

interface YoutubeSearchTagCloud {
    tags: YoutubeSearchTag[];
}

class YoutubeSearchTagFromJSON extends FromAny implements YoutubeSearchTag {
    @Validate(isNumber) pageViews: number;
    @Validate(isBoolean) madeForKids: boolean;
    @Validate(isNumber) videoCategoryId: number;
    @Validate(isString, notEmpty) text: string;
}

class YoutubeSearchTagCloudFromJSON extends FromAny implements YoutubeSearchTagCloud {
    @ChildArray(YoutubeSearchTagFromJSON) tags: YoutubeSearchTagFromJSON[];
}
