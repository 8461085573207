import { DictionaryServiceEnv, TagCloudSiteEnv } from "@dictionary/interface";
import { FeedbackServiceEnv } from "@feedback/interface";
import { TelegramListenEnglishBotUIEnv } from "@telegram/interface";

export const environment: DictionaryServiceEnv &
    FeedbackServiceEnv & { production: boolean } & TelegramListenEnglishBotUIEnv &
    TagCloudSiteEnv = {
    dictionarySearchAPI: "https://functions.yandexcloud.net/d4eq9pv67tv4ma8vn5o2",
    feedbackAPI: "https://functions.yandexcloud.net/d4eoa4tebaen762k5gah",
    production: true,
    telegramBotURL: "https://t.me/Listen2EnglishBot",
    tagCloudBucketUrl: "https://storage.yandexcloud.net/tag-cloud/"
};


